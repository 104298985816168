/* global EM */
import logo from "../assets/logo.svg";
import "../style/Login.css";
import React, { Component } from "react";
import _ from "underscore";
import CustomerDetails from "../components/BookingWizard/CustomerDetails";
import DateSelection from "../components/BookingWizard/DateSelection";
import EventDetails from "../components/BookingWizard/EventDetails";
import LocationDetails from "../components/BookingWizard/LocationDetails";
import PaymentDetails from "../components/BookingWizard/PaymentDetails";
import TimeSelection from "../components/BookingWizard/TimeSelection";
import Spinner from "../components/Spinner";
import { loadStripe } from "@stripe/stripe-js";
import Routes from '../app/Routes'
class Booking extends Component {
  constructor(props) {
    super(props);
    let self = this;
    self.state = {
      isLoading:false,
      eventId: self.props.match.params.eventId,
      event: null,
      location: null,
      dates: [],
      slots: [],
      companyId: null,
      steps: {
        eventDetails: {
          name: "eventDetails",
          visible: true,
          done: true,
          next: () => { return self.state.steps.locationsDetails; },
          skip: () => { return self.skipLocationStep(); },
          stepAfterSkip: () => { 
            self.loadLocationDates(EM.event.get().locationId);
            return self.state.steps.dateSelection; 
          },
        },
        locationsDetails: {
          name: "locationsDetails",
          visible: false,
          data: null,
          done: false,
          next: () => {
            self.loadLocationDates(self.state.steps.locationsDetails.data.locationId);
            return self.state.steps.dateSelection;
          },
          prev: () => { return self.state.steps.eventDetails; }
        },
        dateSelection: {
          name: "dateSelection",
          visible: false,
          done: false,
          next: async() => {
            self.loadSlots(self.state.steps.dateSelection.data);
            return self.state.steps.timeSelection;
          },
          prev: () => { return self.state.steps.locationsDetails; }
        },
        timeSelection: {
          name: "timeSelection",
          visible: false,
          done: false,
          next: () => { return self.state.steps.customerDetails; },
          prev: async() => { 
            return self.state.steps.dateSelection; }
        },
        customerDetails: {
          name: "customerDetails",
          visible: false,
          done: false,
          error:"",
          next: async () => {
            return  await self.placeOrderIfRequired(self.state.steps.paymentDetails);
          },
          prev: () => { return self.state.steps.timeSelection; },
          skip: (currentStep,nextStep) => {
            if(nextStep && nextStep.name === self.state.steps.customerDetails.name)
              return false;
            return self.skipPaymentStep(); 
          },
          stepAfterSkip: () => { return self.state.steps.thankYou; },
        },
        paymentDetails: {
          name: "paymentDetails",
          visible: false,
          done: false,
          prev: () => { return self.state.steps.customerDetails; },
          next: async () => {
            return  await self.placeOrder(self.state.steps.thankYou);
          },
        },
        thankYou: {
          name: "thankYou",
          visible: false
        }
      },
    };
    this.next = this.next.bind(this);
    this.hideAll = this.hideAll.bind(this);
    this.skipLocationStep = this.skipLocationStep.bind(this);
    this.getNextStep = this.getNextStep.bind(this);
    this.loadLocationDates = this.loadLocationDates.bind(this);
    this.loadSlots = this.loadSlots.bind(this);
    this.skipPaymentStep = this.skipPaymentStep.bind(this);
    this.placeOrderIfRequired = this.placeOrderIfRequired.bind(this);
    this.placeOrder = this.placeOrder.bind(this);
  }

  componentDidMount() {
    this.getEvent();
  }

  loadLocationDates(locationId) {
    let self = this;
    let params = { locationId: locationId };
    EM.availableDays.searchEntity(params, {}, true).then(() => {
      self.setState({ dates: EM.availableDays.get() });
    });
  }

  async loadSlots(date) {
    let self = this;
    let locationId =self.state.steps.locationsDetails.data ? self.state.steps.locationsDetails.data.locationId : self.state.event.locationId;
    let params = { locationId: locationId, date: date.strdt };
    let qs = { date: date.strdt,bookByAdmin:false };
    if(this.state.company && this.state.location && this.state.company.companyConfig.mazibook===true && (this.state.location.maziOrganizationCode!==null && this.state.location.maziOrganizationCode!=='')){
      // console.log(Routes.clientApi.locationSlotsV2)
      
      EM.availableSlots.executeGet(null,qs,Routes.clientApi.locationSlotsV2,{locationId}).then((result)=>{
        self.setState({ slots: EM.availableSlots.get() });
      });
    } else{
      
      EM.availableSlots.searchEntity(params, qs, true).then(() => {
        self.setState({ slots: EM.availableSlots.get() });
      });
    }
    
  }
  async getEvent() {
    let self = this;
    let params = { eventId: this.state.eventId };
    EM.event.loadSingleEntity(params).then(() => {
      self.setState({ event: EM.event.get() });
      self.getLocation();
    });
  }

  getLocation() {
    let self = this;
    let event = this.state.event;
    let params = { id: event.locationId };
    EM.location.loadSingleEntity(params).then(() => {
      let location = EM.location.get();
      self.setState({ location: location, companyId: location.companyId });
      self.getCompany();
    });
  }

  getCompany() {
    let self = this;
    let params = { id: self.state.companyId };
    EM.company.loadSingleEntity(params).then(() => {
      let company = EM.company.get();
      self.setState({ company: company });
    });
  }

  skipLocationStep() {
    return !this.state.event.allLocations;
  }

  skipPaymentStep() {
    return !this.state.event.chargeAmount || (this.state.event.chargeAmount && this.state.event.chargeAmount <= 0);
  }

  hideAll() {
    let steps = this.state.steps;
    _.each(steps, (step) => {
      step.visible = false;
    });
    return steps;
  }

 async getNextStep(current) {
    let next = await current.next();
    if (current.skip && current.skip(current,next) === true) {
      next = current.stepAfterSkip();
    }
    next.visible = true;
    return next;
  }

  getPrevStep(current) {
    let next = current.prev();
    if (current.skipPrev && current.skipPrev() === true) {
      next = current.stepBeforeSkip();
    }
    next.visible = true;
    return next;
  }

  async next(data, current) {   
    current.data = data;
    current.done = true;
    this.setState({currentStep:current});
    let steps = Object.assign({}, this.state.steps);
    steps = this.hideAll();
    let next = await this.getNextStep(current);

    steps[next.name] = next;
    if (next) next.visible = true;
    this.setState({ steps });
  }

  async prev(data, current) {
    current.data = {};
    current.done = false;
    let steps = Object.assign({}, this.state.steps);
    steps = this.hideAll();
    let next = await this.getPrevStep(current);
    steps[next.name] = next;
    if (next) next.visible = true;
    this.setState({ steps });
  }

  async placeOrderIfRequired(step) {    
    let self =this;
    const response = await self.placeOrder();
    
    if(response==false){
    
    return self.state.steps.customerDetails
   }
    let skipPayment = this.skipPaymentStep();

    if (skipPayment !== true) return step;
   
   return this.state.steps.paymentDetails.stepAfterSkip;
   
  }
  async placeOrder(step){
    let self =this;
    let steps =self.state.steps; 
    let user = steps.customerDetails.data;
    let childrenName= Array.prototype.map.call(steps.customerDetails.data.childrenNames, function(item) { return item.id; }).join(", ")
    // let location2=EM.location.get()
    // let company=EM.company.get()
    
    // if(company && location2 && company.companyConfig.mazibook===true && (location2.maziOrganizationCode!==null && location2.maziOrganizationCode!=='')){
    //   this.setState({isLoading:true})
    //   const externalResponse = await self.createuserExternal(user,childrenName)
    //   this.setState({isLoading:false})
    //   // console.log(externalResponse)
    //   if(externalResponse.error && externalResponse.error==="The phone field contains an invalid number."){
    //     const customerDetails = this.state.steps.customerDetails
    //     customerDetails.error=externalResponse.error
    //     this.setState(prevState => ({
    //       steps: {
    //         ...prevState.steps,
    //         customerDetails: customerDetails
    //       }
    //     }));
    //     return false
    //   }
    // }
    let date = steps.dateSelection.data.strdt;
    let time = steps.timeSelection.data;
    let location = steps.locationsDetails.data;
     //if chargeamount is 0, place order    
     await EM.eventOrder.create({
      eventId : self.state.event.eventId,
      firstName : user.firstName,
      lastName : user.lastName,
      maziAdult2FirstName:user.firstName2,
      maziAdult2LastName:user.lastName2,
      maziChildrenNames:childrenName,
      phone: user.phoneNumber,
      email:user.email,
      maziAdult2MobileNumber:user.maziAdult2MobileNumber,
      maziAdult2Email:user.maziAdult2Email,
      streetAddress:user.streetAddress,
      city:user.city,
      state:user.state,
      zip:user.zip,
      date: `${date} ${time}`,
      locationId : location ? location.locationId :self.state.event.locationId
    },{}).then((res)=>{
      if(self.state.event.chargeAmount>0){        
        //redirect to stripe
        var prodPaymentMode = process.env.REACT_APP_PAYMENT_MODE ==='prod';
        let sessionId = res.data.sessionId;
        let key = prodPaymentMode===true ? self.state.company.companyConfig.stripePublishableKey:process.env.REACT_APP_STRIPE_API_KEY_TEST;
        loadStripe(key).then(async (stripe)=>{
          if (!stripe) {
              alert("no stripe");
              return;
          }
          const { error } = await stripe.redirectToCheckout({
              sessionId: sessionId
          });
          console.log(error);
        });
        
      }
      

    });
    
  }
  async createuserExternal(user,childrenName){
    return await fetch('https://api.mazibook.com/api/sso/user/createUser', {
    method: 'POST',
    body: JSON.stringify({
      first_name:user.firstName,
      last_name:user.lastName,
      phone:user.phoneNumber.replace(/[^\d]/g, ''),
      gender :"3",
      organization_code:EM.location.get().maziOrganizationCode,  
      children_names:childrenName,  
      adult2_first_name:user.firstName2,  
      adult2_last_name:user.lastName2   

    }),
    headers: {
      'Content-type': 'application/json; charset=UTF-8',
      'Authorization': 'Bearer uziSHArwX4vpUyqxQEOlaBxPRafTLopCwbCMc0vzbNiXXNXkmLUTlgxzcECN9'
    }
    })
    .then(function(response){ 
    return response.json()})
    .then(function(data)
    {
      
      console.log(data)
      return data
  }).catch(error => console.error('Error:', error)); 
  }
  render() {
    if(this.state.isLoading){
      return (
        <div className={this.props.className}>
          <Spinner text={EM.t("util.loading")} classes="small" />
        </div>
      );
    }
    if (EM.allLoaded(EM.event, EM.location)) {
      let steps = this.state.steps;
      return (
        <div key="contents" className="page container-fluid">
          {/* <header className="header py-3" hidden={false}>
            <section className="container-fluid d-flex">
              <a href="/" className="">
                <img src={logo} alt="Flashops" />
              </a>
            </section>
          </header> */}
          <main className="heightFull">
            <div className="row heightFull">
              <div className="col-lg-5 aboutBG d-none d-lg-flex">
                <div className="row pt-5 px-4 h-100">
                  <div className="col-11 px-0 mt-5">
                    {/* <h4>About Company</h4>
                    <p>Booking Copy You can book your date and time simply from our website, Flashop app, or in person at one of our many retail locations we serve. Our promotional special is a digital download of your favorite image for $20 with over 25 images to choose from. Also other simple pricing package specials to choose from.</p>
                    <p>Want us to come to you?</p>
                    <p>Photography session and delivery.</p>
                    <p>Now the fun part. You confirmed your photography session and location to bring your loved ones Dress to impress to be Photographed.</p>
                    <p>We will take over 25+ images. Directly after the photo session your photos will be delivered to your Flashop app. Your Photographer will show you exactly how to select your Favorite image. When unlock it all the watermarks are removed so you can download it to your mobile device share it with friends and family or put it in your favorite editing app.</p>
                    <p>It’s all about the Digital and instant gratification.</p>
                    <p>If you decide you want all of your images unlocked. $149.99 groups under six. When you unlock the digital you also receive an text with all the images In a zip file.</p>
                    <p>Check out or print specials as well With sizes up to 30 x 40. At AM Studios We give you the most convenient way to have professional portraits scheduled, photographed, and delivered.</p>
                    <p>Thank you for your business and we look forward to serving you.</p> */}
                  </div>
                  <div className="col-12 px-0 py-2 mt-auto">
                    <h5>© 2024 Flashop. All rights reserved.</h5>
                  </div>
                </div>
              </div>
              <div className="col-lg-5 offset-lg-1 pt-5 mt-3">
                <div className="form mt-3 mt-lg-0">
                  <ul className="progress-bar px-4 px-sm-5 px-md-3 d-flex align-items-center flex-row position-relative">
                    <li className="progress-bar__dot full">
                      <span className="stepContent">Event</span>
                    </li>
                    <li className="progress-bar__connector"></li>
                    <li className={"progress-bar__dot " + (steps.locationsDetails.visible || steps.locationsDetails.done ? "full" : "")}>
                      <span className="stepContent">Location</span>
                    </li>
                    <li className="progress-bar__connector"></li>
                    <li className={"progress-bar__dot " + (steps.dateSelection.visible || steps.dateSelection.done ? "full" : "")}>
                      <span className="stepContent">Select Date</span>
                    </li>
                    <li className="progress-bar__connector"></li>
                    <li className={"progress-bar__dot " + (steps.timeSelection.visible || steps.timeSelection.done ? "full" : "")}>
                      <span className="stepContent">Select Time</span>
                    </li>
                    <li className="progress-bar__connector"></li>
                    <li className={"progress-bar__dot " + (steps.customerDetails.visible || steps.customerDetails.done ? "full" : "")}>
                      <span className="stepContent">Customer Info</span>
                    </li>
                  </ul>
                  {/* Steps Start here.... */}
                  <div className="col-lg-10 mx-auto px-0">
                    <div hidden={!steps.eventDetails.visible}>
                      <EventDetails
                        next={(data, current) => {
                          this.next(data, current);
                        }}
                        step={this.state.steps.eventDetails}
                        {...this.props}
                      ></EventDetails>
                    </div>
                    <div hidden={!steps.locationsDetails.visible}>
                      <LocationDetails location={this.state.location} prev={(data, current) => { this.prev(data, current); }} next={(data, current) => { this.next(data, current); }} step={this.state.steps.locationsDetails}></LocationDetails>
                    </div>
                    <div hidden={!steps.dateSelection.visible}>
                      <DateSelection prev={(data, current) => { this.prev(data, current); }} dates={this.state.dates} next={(data, current) => { this.next(data, current); }} step={this.state.steps.dateSelection}></DateSelection>
                    </div>
                    <div hidden={!steps.timeSelection.visible}>
                      <TimeSelection 
                      mazibit={(this.state.company && this.state.location && this.state.company.companyConfig.mazibook===true && (this.state.location.maziOrganizationCode!==null && this.state.location.maziOrganizationCode!==''))}
                      loadSlots={(value)=>{console.log(value);this.loadSlots(this.state.steps.dateSelection.data)}} dateSelected={this.state.steps.dateSelection.data} prev={(data, current) => { this.prev(data, current); }} date={steps.dateSelection.data ? steps.dateSelection.data.strdt : ''} slots={this.state.slots} next={(data, current) => { this.next(data, current); }} step={this.state.steps.timeSelection}></TimeSelection>
                    </div>
                    <div hidden={!steps.customerDetails.visible}>
                      {
                        this.state.company &&
                        <CustomerDetails error={this.state.steps.customerDetails.error} prev={(data, current) => { this.prev(data, current); }} next={(data, current) => { this.next(data, current); }} step={this.state.steps.customerDetails}></CustomerDetails>}
                    </div>
                    <div hidden={!steps.paymentDetails.visible}>
                      <PaymentDetails prev={(data, current) => { this.prev(data, current); }} next={(data, current) => { this.next(data, current); }} step={this.state.steps.paymentDetails}></PaymentDetails>
                    </div>
                    <div hidden={!steps.thankYou.visible}>
                      <div className="step step1">
                        <div className="border border-primary border-2 text-dark text-uppercase p-3 d-flex w-100">
                          Thank you!
                        </div>
                        <p className="my-4">{this?.state?.event?.closeMessage}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </main>

        </div>
      );
    }
    return (
      <div className={this.props.className}>
        <Spinner text={EM.t("util.loading")} classes="small" />
      </div>
    );
  }
}

export default Booking;
