/* global EM */
import React, { Component } from "react";
import { Button } from "reactstrap";
import Spinner from "../Spinner";
import InputMask from 'react-input-mask';
import Routes from '../../app/Routes';
import { Route } from "react-router-dom";
import { WithContext as ReactTags } from 'react-tag-input';
import PanelModal from '../PanelModal';
import ConfirmMaziCode from './ConfirmMaziCode'
import { Button as ButtonPop, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import Select from 'react-select';
import statesData from '../../util/statesUS.json'

export default class CustomerDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: {
        firstName: '',
        lastName: '',
        phoneNumber: '',
        email: '',
        firstName2:'',
        lastName2: '',
        maziAdult2MobileNumber: '',
        maziAdult2Email: '',
        streetAddress: '',
        city: '',
        state: '',
        zip: '',
        maziOrganizationCode:'',
        childrenNames:[]
      },
      popUpError:'',
      isPopOpen:false,
      isPopOpen2:false,
      onlineRegistration:true,
      location:false,
      company:false
    };
    this.onTextChange = this.onTextChange.bind(this);
    this.validate = this.validate.bind(this);
    this.confirmAndDownload = this.confirmAndDownload.bind(this);
    this.ShowError = this.ShowError.bind(this);
    this.onError = this.onError.bind(this);
    this.openPopUp = this.openPopUp.bind(this);
    this.openPopUp2 = this.openPopUp2.bind(this);
    this.mapStates = this.mapStates.bind(this);
    this.closePopUp = this.closePopUp.bind(this);
    this.handleValidCode = this.handleValidCode.bind(this);
    this.updateOnlineRegistration = this.updateOnlineRegistration.bind(this);
    this.validate();
    console.log(EM)
  }
  handleValidCode(){
    this.setState({isOpen2:false})
    this.updateOnlineRegistration()
  }
  openPopUp2(){
    this.setState({isOpen2:true})
  }
  closePopUp(){
    this.setState({isOpen2:false})
  }
  updateOnlineRegistration(){
   
    const self=this
    const onlineRegistration = this.state.onlineRegistration
    EM.event.executePut({eventId:EM.event.get().eventId, onlineBooking:!onlineRegistration},null,Routes.clientApi.updateEventBooking, {},true)
    .then((result)=>{
      self.setState({onlineRegistration:!self.state.onlineRegistration})
    });
  }
  ShowError() {
    if (this.state.popUpError != "") {
        return <div className="row">
            <div className="col">
                <div className="alert alert-danger" role="alert">
                    {this.state.popUpError}
                </div>
            </div>
        </div>
    }
}
  onError(){
    if(this.props.error){
      return <div className="row">
            <div className="col">
                <div className="alert alert-danger" role="alert">
                    {this.props.error}
                </div>
            </div>
        </div>
    }
  }
  handleDelete = i => {
    this.setState(prevState => ({
      user: {
        ...prevState.user,
        childrenNames: this.state.user.childrenNames.filter((tag, index) => index !== i),
      }
    }))
  };

  handleAddition = (tag) => {
    this.setState(prevState => ({
      user: {
        ...prevState.user,
        childrenNames: [...this.state.user.childrenNames, tag],
      }
    }))
  }
  handleInputBlur= (tag) => {
    if(tag==="")return
    this.setState(prevState => ({
      user: {
        ...prevState.user,
        childrenNames: [...this.state.user.childrenNames, {id:tag,text:tag}]
      }
    }))
    
  }

  handleDrag = (tag, currPos, newPos) => {
    const newTags = this.state.user.childrenNames.slice();

    newTags.splice(currPos, 1);
    newTags.splice(newPos, 0, tag);

    this.setState(prevState => ({
      user: {
        ...prevState.user,
        childrenNames: newTags
      }
    }))
  }
  async componentDidMount(){
    this.setState({location:EM.location.get()})
     this.setState({company:EM.company.get()})
  }
  
  onTextChange(event, field) {
    let user = Object.assign(this.state.user, {});
    user[field] = field === 'state' ? event?.value || '' : event.target.value;
    this.setState({ user });
    this.validate();
  }

  validate(){
    let user=this.state.user
    return user.firstName.trim().length >0 &&
    user.lastName.trim().length >0 &&
    user.phoneNumber.trim().length >0 &&
    user.email.trim().length >0 ;
  }
  handleDownload = (locationId) => {
    var url = 'https://api.flashopllc.com/api/appointment/printschedule?LocationId='
 
    window.open(`${url+locationId}`,)
  }
  confirmAndDownload(){
    const maziOrganizationCode = this.state.user.maziOrganizationCode
    if(maziOrganizationCode===EM.location.get().maziOrganizationCode){
      this.handleDownload(this.state.location.locationId)
      this.setState({isPopOpen:false})
    } else {
      this.setState({popUpError:"Authorization Code Given!"})
    }
  }
  openPopUp(){
    this.setState({isPopOpen:true,popUpError:''})
    this.setState(prevState => ({
      user: {
        ...prevState.user,
        maziOrganizationCode: ""
      }
    }));
  }

  mapStates(){
    return statesData.map(state => ({
      value: state.name,
      label: state.name
    }))
  }

  render() {
    const KeyCodes = {
      comma: 188,
      enter: 13
    };
    
    const delimiters = [KeyCodes.comma, KeyCodes.enter];
    if (EM.allLoaded(EM.event)) {
      let jsxRender
      
      if(this.state.company && this.state.location && this.state.company.companyConfig.mazibook===true && (this.state.location.maziOrganizationCode!==null && this.state.location.maziOrganizationCode!=='')){
        jsxRender=(
          <div className="step step5">
            <div className="row">
              <span className="font-weight-bold">{this.state.user.lastName && ` ${this.state.user.lastName} `}</span>
              <span style={{ whiteSpace: 'pre' }} className="font-weight-bold">{this.state.user.firstName && `  ${this.state.user.firstName}`}</span>
              <span style={{ whiteSpace: 'pre' }} className="font-weight-bold">{this.state.user.firstName && this.state.user.firstName2 && ` & `}</span>
              <span style={{ whiteSpace: 'pre' }} className="font-weight-bold">{this.state.user.firstName2 && `${this.state.user.firstName2}`}</span>
              <span style={{ whiteSpace: 'pre' }} className="font-weight-bold">{this.state.user.lastName !== this.state.user.lastName2 ? ` ${this.state.user.lastName2}` : null}</span>
              <br/>
            </div>
            <div className="row">
              {this.state.user.childrenNames.map((name, i) => { 
              const childrenArray=this.state.user.childrenNames  
              return <span key={name.id} style={{ whiteSpace: 'pre' }} className="font-weight-bold mb-2">{name.id}{(childrenArray.length>1 && i!=(childrenArray.length-1))?', ':''}</span>;
            })
          }
          </div>
            {this.onError()}
            <div className="row">
              <div className="mb-3 col-sm d-flex flex-column">
                <span className="form-label flex-grow-1 fw-bold d-block mb-1">*Head Of Household First Name</span>
                <input placeholder="first name" autoFocus className="form-control m-0 border border-2 border-primary p-2" value={this.state.user.firstName} onChange={(event) => { this.onTextChange(event, 'firstName') }}></input>
              </div>
              <div className="mb-3 col-sm d-flex flex-column">
                <span className="form-label flex-grow-1 fw-bold d-block mb-1">*Head Of Household Last Name</span>
                <input placeholder="last name" className="form-control m-0 border border-2 border-primary p-2" value={this.state.user.lastName} onChange={(event) => { this.onTextChange(event, 'lastName') }}></input>
              </div>
              </div>
              <div className="row">
              <div className="mb-3 col-sm d-flex flex-column">
                <span className="form-label flex-grow-1 fw-bold d-block mb-1">*Head Of Household MOBILE NUMBER</span>
                <InputMask placeholder="(999) 999-9999" className="form-control m-0 border border-2 border-primary p-2" value={this.state.user.phoneNumber} onChange={(event) => { this.onTextChange(event, 'phoneNumber') }} mask="(999) 999-9999" maskChar=" " />
              </div>
              <div className="mb-3 col-sm d-flex flex-column">
                <span className="form-label flex-grow-1 fw-bold d-block mb-1">*Head Of Household Email</span>
                <input placeholder="abc@abc.com" className="form-control m-0 border border-2 border-primary p-2" value={this.state.user.email} onChange={(event) => { this.onTextChange(event, 'email') }}></input>
              </div>
              </div>
              <div className="row">
                <div className="mb-3 col-sm d-flex flex-column">
                  <span className="form-label flex-grow-1 fw-bold d-block mb-1">Spouse First Name</span>
                  <input placeholder="Spouse first name" autoFocus className="form-control m-0 border border-2 border-primary p-2" value={this.state.user.firstName2} onChange={(event) => { this.onTextChange(event, 'firstName2') }}></input>
                </div>
                <div className="mb-3 col-sm d-flex flex-column">
                  <span className="form-label flex-grow-1 fw-bold d-block mb-1">Spouse Last Name</span>
                  <input placeholder="Spouse last name " className="form-control m-0 border border-2 border-primary p-2" value={this.state.user.lastName2} onChange={(event) => { this.onTextChange(event, 'lastName2') }}></input>
                </div>
               
              </div>
              <div className="row">
                <div className="mb-3 col-sm">
                  <span className="form-label fw-bold d-block mb-1">Spouse Mobile Number</span>
                  <InputMask placeholder="(999) 999-9999" className="form-control m-0 border border-2 border-primary p-2" value={this.state.user.maziAdult2MobileNumber} onChange={(event) => { this.onTextChange(event, 'maziAdult2MobileNumber') }} mask="(999) 999-9999" maskChar=" " />
                </div>
                <div className="mb-3 col-sm">
                  <span className="form-label fw-bold d-block mb-1">Spouse Email </span>
                  <input placeholder="abc@abc.com" className="form-control m-0 border border-2 border-primary p-2" value={this.state.user.maziAdult2Email} onChange={(event) => { this.onTextChange(event, 'maziAdult2Email') }}></input>
                </div>
              </div>
              <div className="row">
                <div className="mb-3 col-sm">
                  <span className="form-label fw-bold d-block mb-1">Street Address</span>
                  <input placeholder="Address" className="form-control m-0 border border-2 border-primary p-2" value={this.state.user.streetAddress} onChange={(event) => { this.onTextChange(event, 'streetAddress') }}></input>
                </div>
                <div className="mb-3 col-sm">
                  <span className="form-label fw-bold d-block mb-1">City </span>
                  <input placeholder="City" className="form-control m-0 border border-2 border-primary p-2" value={this.state.user.city} onChange={(event) => { this.onTextChange(event, 'city') }}></input>
                </div>
              </div>
              <div className="row">
                <div className="mb-3 col-sm">
                  <span className="form-label fw-bold d-block mb-1">State</span>
                  {/* <input placeholder="State" className="form-control m-0 border border-2 border-primary p-2" value={this.state.user.state} onChange={(event) => { this.onTextChange(event, 'state') }}></input> */}
                  <Select
                  className="single-select"
                  classNamePrefix="single-select"
                  isSearchable={true}
                  isMulti={false}
                  isClearable={true}
                  options={this.mapStates()}
                  placeholder={'Please select State'}
                  onChange={(selection) => {                    
                    this.onTextChange(selection, 'state');
                  }}
                />
                </div>
                <div className="mb-3 col-sm">
                  <span className="form-label fw-bold d-block mb-1">Zip </span>
                  <input placeholder="Zip" className="form-control m-0 border border-2 border-primary p-2" value={this.state.user.zip} onChange={(event) => { this.onTextChange(event, 'zip') }}></input>
                </div>
              </div>
              <div className="row">
              <div className="mb-3 col-sm">
                <span className="form-label fw-bold d-block mb-1">Children Names</span>
                {/* <input placeholder="Children Names" className="form-control m-0 border border-2 border-primary p-2" value={this.state.user.childrenNames} onChange={(event) => { this.onTextChange(event, 'childrenNames') }}></input> */}
                <ReactTags
                placeholder="Add Children"
                classNames={{
                  // tags: 'tagsClass',
                  // tagInput: 'tagInputClass',
                  tagInputField: 'form-control m-0 border border-2 border-primary p-2',
                  // selected: 'selectedClass',
                  // tag: 'tagClass',
                  // remove: 'removeClass',
                  // suggestions: 'suggestionsClass',
                  // activeSuggestion: 'activeSuggestionClass',
                  // editTagInput: 'editTagInputClass',
                  // editTagInputField: 'editTagInputField',
                  // clearAll: 'clearAllClass',
                }}
                tags={this.state.user.childrenNames}
                delimiters={delimiters}
                handleDelete={this.handleDelete}
                handleAddition={this.handleAddition}
                handleInputBlur={this.handleInputBlur}
                handleDrag={this.handleDrag}
                inputFieldPosition="bottom"
                autocomplete
              />
              <i className="form-label fw-bold d-block mb-1 small text-muted">Enter Oldest child's name then press comma or enter key, repeat for additional children.</i>                
              </div>
              
            </div>
            <div>
            
              <Button disabled={!this.validate() || !this.state.onlineRegistration} className="btn btn-primary btn-block py-2 w-100 text-uppercase mt-3" onClick={() => { this.props.next(this.state.user, this.props.step) }}>Next</Button>
              <Button disabled={!this.state.onlineRegistration} className="btn btn-dark btn-block w-100 py-2 text-uppercase mt-2" onClick={()=>{this.props.prev({},this.props.step)}}>Back</Button>
              </div>
              {/* <div className="row mt-3">
               <div className="col-sm text-center">_________For Official Use Only_________</div>
              </div>
              <div>
              <ButtonPop className="btn btn-primary btn-block py-2 w-100 text-uppercase mt-3" onClick={() => { this.openPopUp() }} style={{color:'black !important',fontWeight:'600'}}>Print Schedule</ButtonPop>
              <ButtonPop className="btn btn-dark btn-block w-100 py-2 text-uppercase mt-2" onClick={()=>this.openPopUp2()}>{this.state.onlineRegistration===true?'Suspend Online Registration':'Resume Online Registration'}</ButtonPop>
              </div> */}

              <PanelModal isOpen={this.state.isPopOpen} className={'panel-full'} toggle={(event) => { this.setState({isPopOpen:false}) }}>
            <ModalHeader>Confirm Identity</ModalHeader>
            <ModalBody> 
            {this.ShowError()}
            <div className="">
            <div className="row">
            <div className="mb-3 col-sm">
                <span className="form-label fw-bold d-block mb-1">Authorization Code</span>
                <input placeholder="Authorization Code" autoFocus className="form-control m-0 border border-2 border-primary p-2" value={this.state.user.maziOrganizationCode} onChange={(event) => { this.onTextChange(event, 'maziOrganizationCode') }}></input>
              </div>
            </div>
            </div>
            </ModalBody>
            <ModalFooter>
                    <Button color="light" className="mr-auto" onClick={this.confirmAndDownload}>Submit</Button>
                <Button className="pull-left" onClick={()=>this.setState({isPopOpen:false})}>
                    {EM.t('util.closeButton')}
                </Button>
            </ModalFooter>
        </PanelModal>
        <ConfirmMaziCode isValid={(data)=>{if(data==true)this.handleValidCode()}} isOpen={this.state.isOpen2} onClose={this.state.closePopUp} close={this.closePopUp}/>
          </div>
        )
      } else {
        jsxRender=(
          <div className="step step5">
            <div className="mt-3">
              <div className="mb-3">
                <span className="form-label fw-bold d-block mb-1">First Name</span>
                <input placeholder="first name" autoFocus className="form-control m-0 border border-2 border-primary p-2" value={this.state.user.firstName} onChange={(event) => { this.onTextChange(event, 'firstName') }}></input>
              </div>
              <div className="mb-3">
                <span className="form-label fw-bold d-block mb-1">Last Name</span>
                <input placeholder="last name" className="form-control m-0 border border-2 border-primary p-2" value={this.state.user.lastName} onChange={(event) => { this.onTextChange(event, 'lastName') }}></input>
              </div>
              <div className="mb-3">
                <span className="form-label fw-bold d-block mb-1">Phone</span>
                <InputMask placeholder="(999) 999-9999" className="form-control m-0 border border-2 border-primary p-2" value={this.state.user.phoneNumber} onChange={(event) => { this.onTextChange(event, 'phoneNumber') }} mask="(999) 999-9999" maskChar=" " />
              </div>
              <div className="mb-3">
                <span className="form-label fw-bold d-block mb-1">Email</span>
                <input placeholder="abc@abc.com" className="form-control m-0 border border-2 border-primary p-2" value={this.state.user.email} onChange={(event) => { this.onTextChange(event, 'email') }}></input>
              </div>
            </div>
            <div>
              <Button disabled={!this.validate()} className="btn btn-primary btn-block py-2 w-100 text-uppercase mt-3" onClick={() => { this.props.next(this.state.user, this.props.step) }}>Next</Button>
              <Button className="btn btn-dark btn-block w-100 py-2 text-uppercase mt-2" onClick={()=>{this.props.prev({},this.props.step)}}>Back</Button>
              </div>
          </div>
        )
      }
      return jsxRender
    }
    return (
      <div className={this.props.className}>
        <Spinner text={EM.t("util.loading")} classes="small" />
      </div>
    );
  }
}
